import React from 'react';

export default function ({ key, value }) {
  const isBrowser = typeof window !== 'undefined';
  let parsedLocalStorage = {};

  if (isBrowser) {
    parsedLocalStorage = JSON.parse(localStorage.getItem(key) || '{}');
  }

  const initialValue = Object.keys(parsedLocalStorage).length > 0 ? parsedLocalStorage : value;

  const [localStorageState, setLocalStorageState] = React.useState(initialValue);

  const handleUpdateLocalStorageState = React.useCallback(
    updatedValue => {
      setLocalStorageState(updatedValue);
      localStorage.setItem(key, JSON.stringify(updatedValue));
    },
    [key],
  );

  return [localStorageState, handleUpdateLocalStorageState];
}
